import React from 'react';
import globalStyles from '../../global.module.css';
import { CLAIM_STATES, getSelectedFlightDates, getSelectedFlightCodes } from '../../util';
import { sendDocIQRequest, generateFailures } from './ReceiptScanningUtil';

import ClaimForm from './ClaimForm';
import BagClaimForm from './BagClaimForm';
import DisputeForm from './DisputeForm';
import ResolutionForm from './ResolutionForm';

class ClaimPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            claimState: CLAIM_STATES.CLAIM,
            filename: "",
            amount: "",
            errors: [],
            dispute: false,
            docIQResponse: null
        };

        this.generateDocumentIntelligenceReport = this.generateDocumentIntelligenceReport.bind(this);
        this.submitDispute = this.submitDispute.bind(this);
        this.publishClaim = this.publishClaim.bind(this);
    }

    generateDocumentIntelligenceReport(filename, amount, sendToLoading = true) {
        if (sendToLoading) this.setState({claimState: CLAIM_STATES.LOADING});
        let modifiedFilename = filename.split("\\");
        modifiedFilename = modifiedFilename[modifiedFilename.length - 1];
        sendDocIQRequest(modifiedFilename, (response) => {
            console.log("DocIQ response: ", response);
            if (sendToLoading) {
                generateFailures(this.props.category, this.props.baseData.airlineInfo.policy, 
                    getSelectedFlightDates(this.props.baseData.flights, this.props.data.flights), 
                    getSelectedFlightCodes(this.props.baseData.flights, this.props.data.flights), 
                    response.analyzeResult.documents, amount, (claimErrors) => {
                        this.setState({claimState: CLAIM_STATES.RESOLUTION, errors: claimErrors, filename: filename, amount: amount});
                    });
            }
            else {
                let receiptItems = response.analyzeResult.documents[0].fields.Items.valueArray.map((field) => field.valueObject.Description.content);
                let receiptPrices = response.analyzeResult.documents[0].fields.Items.valueArray.map((field) => field.valueObject.TotalPrice.content);
                console.log("Receipt items: ", receiptItems, receiptPrices);
                this.setState({docIQResponse: {items: receiptItems, prices: receiptPrices}});
            }
        });
    }

    submitDispute() {
        this.setState({claimState: CLAIM_STATES.RESOLUTION, dispute: true});
    }

    publishClaim(status) {
        let claim = {};
        claim["status"] = status;
        claim["amount"] = this.state.amount;
        // amount, date, vendor, status
        if (this.props.category == "Transportation Reimbursement" || this.props.category == "Hotel Reimbursement" || this.props.category == "Meals Reimbursement") {
            if (this.state.filename.includes("hilton")) {
                claim["date"] = "1/7/24";
                claim["vendor"] = "Hilton Pittsburgh Downtown";
            }
            else if (this.state.filename.includes("hyatt")) {
                claim["date"] = "5/7/24";
                claim["vendor"] = "Hyatt Irvine Downtown";
            }
            else if (this.state.filename.includes("panera")) {
                claim["date"] = "1/12/24";
                claim["vendor"] = "Panera Pittsburgh Airport";
            }
            else if (this.props.category == "Transportation Reimbursement") {
                claim["amount"] = Math.min(this.state.amount, 50);
                claim["date"] = "1/12/24";
                claim["vendor"] = "Avis Pittsburgh Airport";
            }
            else { // marriott
                claim["date"] = "1/12/24";
                claim["vendor"] = "Marriott Pittsburgh Airport South";
            }
        }
        else {
            claim["date"] = "10/12/24";
            claim["vendor"] = "";
            if (this.state.filename.includes("clothing")) {
                claim["vendor"] = "Macy's Chicago";
            }
            else if (this.state.filename.includes("medicine")) {
                claim["vendor"] = "CVS Pharmacy Chicago";
            }
        }
        this.props.submit(claim);
    }

    render() {
        if (window.innerWidth < 1005) {
            return (<div />);
        }
        return (
            <div className={globalStyles.popupBackground} onClick={this.props.close}>
                <div className={globalStyles.popupOuter} onClick={(e) => e.stopPropagation()}>
                    <ClaimForm 
                        state={this.state.claimState}
                        submit={this.generateDocumentIntelligenceReport} 
                        close={this.props.close} 
                        category={this.props.category} 
                        docIQResponse={this.state.docIQResponse}
                        baseData={this.props.baseData}
                        errors={this.state.errors} />
                    <DisputeForm 
                        state={this.state.claimState} 
                        submit={this.submitDispute} 
                        close={this.props.close}
                        category={this.props.category} 
                        amount={this.state.amount} />
                    <ResolutionForm 
                        state={this.state.claimState}
                        errors={this.state.errors} 
                        submit={this.publishClaim} 
                        refile={() => this.setState({claimState: CLAIM_STATES.CLAIM})}
                        dispute={() => this.setState({claimState: CLAIM_STATES.DISPUTE})}
                        close={this.props.close} 
                        isDispute={this.state.dispute} 
                        category={this.props.category}
                        amount={this.state.amount} />
                </div>
            </div>
        );
    }
}

export default ClaimPopup;