import React from 'react';
import styles from './Footer.module.css';

import { DETAILS_STATES, getBoolCount, CLAIM_STATUS, PAYMENT_STATES, MENU_STATES } from './util';

export class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.checkCanContinue = this.checkCanContinue.bind(this);
        this.generateInactiveText = this.generateInactiveText.bind(this);
        this.generateActiveText = this.generateActiveText.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    checkCanContinue() {
        if (this.props.detailsState == DETAILS_STATES.LOOKUP) {
            return false;
        }
        else if (this.props.detailsState == DETAILS_STATES.FLIGHT) {
            return getBoolCount(this.props.data.flights) > 0;
        }
        else if (this.props.detailsState == DETAILS_STATES.PASSENGERS) {
            return getBoolCount(this.props.data.passengers) > 0;
        }
        else if (this.props.detailsState == DETAILS_STATES.ELIGIBILITY) {
            let foundAccepted = false;
            for (let i = 0; i < this.props.data.claims.length; i++) {
                if (this.props.data.claims[i]["status"] == CLAIM_STATUS.ACCEPTED) {
                    foundAccepted = true;
                    break;
                }
            }
            return true;
            return foundAccepted;
        }
        else if (this.props.detailsState == DETAILS_STATES.CONFIRM) {
            if (this.props.data.paymentForm == PAYMENT_STATES.UNDECIDED) {
                return this.props.data.paymentFormSelected != PAYMENT_STATES.UNDECIDED;
            }
            return this.props.data.paymentDetails != "";
        }
        return true;
    }

    generateInactiveText() {
        if (this.props.detailsState == DETAILS_STATES.FLIGHT) {
            return "Select a Flight to Continue";
        }
        else if (this.props.detailsState == DETAILS_STATES.PASSENGERS) {
            return "Select Passengers to Continue";
        }
        else if (this.props.detailsState == DETAILS_STATES.ELIGIBILITY) {
            return "Confirm $0 in Claims";
        }
        else if (this.props.detailsState == DETAILS_STATES.CONFIRM) {
            if (this.props.data.paymentForm == PAYMENT_STATES.UNDECIDED) {
                return "Select Payout Option";
            }
            return "Submit";
        }
        return "Please fill out all required fields.";
    }

    generateActiveText() {
        return "Continue";
    }

    updateState() {
        if (this.props.detailsState == DETAILS_STATES.FLIGHT) {
            this.props.updateState(DETAILS_STATES.PASSENGERS);
        }
        else if (this.props.detailsState == DETAILS_STATES.PASSENGERS) {
            this.props.updateState(DETAILS_STATES.ELIGIBILITY);
        }
        else if (this.props.detailsState == DETAILS_STATES.ELIGIBILITY) {
            this.props.updateState(DETAILS_STATES.CONFIRM);
        }
        else if (this.props.detailsState == DETAILS_STATES.CONFIRM) {
            if (this.props.data.paymentForm == PAYMENT_STATES.UNDECIDED) {
                this.props.updatePaymentForm(this.props.data.paymentFormSelected);
            }
            else {
                let totalAccepted = 0;
                for (let i = 0; i < this.props.data.claims.length; i++) {
                    if (this.props.data.claims[i]["status"] == CLAIM_STATUS.ACCEPTED) {
                        totalAccepted += this.props.data.claims[i]["amount"];
                    }
                }
                this.props.submitClaim(totalAccepted, this.props.data.paymentDetails);
            }
        }
    }

    render() {
        if (window.innerWidth < 1005) {
            if (this.props.detailsState == DETAILS_STATES.LOOKUP ||
                this.props.menuState == MENU_STATES.RESOLUTION) {
                return (<div />);
            }
            else if (this.checkCanContinue()) {
                return (
                    <div className={styles.mobileFooter} id={styles.mobileFooterActive} onClick={this.updateState}>
                        <div className={styles.mobileFooterText} id={styles.mobileFooterActiveText}>{this.generateActiveText()}</div>
                    </div>
                );
            }
            else {
                return (
                    <div className={styles.mobileFooter} id={styles.mobileFooterInactive}>
                        <div className={styles.mobileFooterText} id={styles.mobileFooterInactiveText}>{this.generateInactiveText()}</div>
                    </div>
                );
            }
        }
        return (
            <div />
        )
    }
}
export default Footer;