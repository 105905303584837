export function TrimList(list) {
    let len = list.length;
    if (list[len - 2] == ',') {
        list = list.substring(0, len - 2);
    }
    return list;
}

export function ContainsEligibleFlights(flightList, selectedFlights) {
    for (let i = 0; i < flightList.length; i++) {
        if (selectedFlights[i] && flightList[i].status != FLIGHT_STATUS.UNAFFECTED && flightList[i].status != FLIGHT_STATUS.WEATHER) {
            return true;
        }
    }
    return false;
}

export function getErrorTitle(error) {
    if (error == CLAIM_ERRORS.DATE) {
        return "Hotel On Different Day";
    }
    else if (error == CLAIM_ERRORS.LOCATION) {
        return "Hotel In Incorrect City";
    }
    else if (error == CLAIM_ERRORS.RECEIPT) {
        return "Receipt Not Provided";
    }
    else if (error == CLAIM_ERRORS.COST) {
        return "Cost Exceeds Policy Limit";
    }
    return "";
}

export function getErrorSubtitle(error) {
    if (error == CLAIM_ERRORS.DATE) {
        return `Your flight was cancelled on a different day than shown in your receipt.`;
    }
    else if (error == CLAIM_ERRORS.LOCATION) {
        return `Your flight was cancelled in a different city than shown in your receipt.`;
    }
    else if (error == CLAIM_ERRORS.RECEIPT) {
        return "Your claim is missing a receipt.";
    }
    else if (error == CLAIM_ERRORS.COST) {
        return "Your reimbursement requested exceeds the policy limit set by the Airline.";
    }
    return "";
}

export function getBoolCount(arr) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i]) {
            count++;
        }
    }
    return count;
}

export function isInteger(amount) {
    let amt = parseInt(amount);
    if (isNaN(amt)) {
        let nums = amount.split("$");
        amt = parseInt(nums[nums.length - 1]);
        if (isNaN(amt)) {
            return false;
        }
    }
    return true;
}

export function returnInteger(amount) {
    let amt = parseInt(amount);
    if (isNaN(amt)) {
        let nums = amount.split("$");
        amt = parseInt(nums[nums.length - 1]); 
    }
    return amt;
}

export function isValidUUID(uuid) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
}  

export function sendHTTPRequest({link, request, decodeCallback, responseCallback, errorCallback} = {}) {
    fetch(link, request)
    .then(decodeCallback)
    .then(responseCallback)
    .catch(errorCallback);
}

export function getAirportName(abbreviation) {
    switch(abbreviation) {
        case "ORD": return "Chicago O'Hare";
        case "SFO": return "San Francisco";
        default: return "";
    }
}

export function getFlightStatus(delay) {
    if (delay == 0) {
        return FLIGHT_STATUS.UNAFFECTED;
    }
    if (delay < 60*60*4) {
        return FLIGHT_STATUS.SHORT_DELAY;
    }
    return FLIGHT_STATUS.LONG_DELAY;
}

export function getSelectedFlightDates(baseFlights, selectedFlights) {
    let flightDates = [];
    for (let i = 0; i < baseFlights.length; i++) {
        if (selectedFlights[i]) {
            let date = new Date(baseFlights[i].date);
            flightDates.push(date);
        }
    }
    return flightDates;
}

export function getSelectedFlightCodes(baseFlights, selectedFlights) {
    let flightCodes = [];
    for (let i = 0; i < baseFlights.length; i++) {
        if (selectedFlights[i]) {
            flightCodes.push(baseFlights[i].routeStartAbr);
        }
    }
    return flightCodes;
}

export const MENU_STATES = Object.freeze({
    ISSUE: 0,
    LOADING: 1,
    DETAILS: 2,
    CONFIRM: 3,
    RESOLUTION: 4
});

export const DETAILS_STATES = Object.freeze({
    LOOKUP: 0,
    FLIGHT: 1,
    PASSENGERS: 2, 
    ELIGIBILITY: 3,
    CONFIRM: 4,
    COMPLETE: 5,
    FEEDBACK: 6,
    BAGS: 7,
    BAG_ELIGIBILITY: 8,
    BAG_INFORMATION: 9,
    BAG_IDENTIFICATION: 10,
    BAG_CONFIRMATION: 11,
    CHAT: 12
});
export function getDetailsStateText(state) {
    switch (state) {
        case DETAILS_STATES.LOOKUP: return "Flight Lookup";
        case DETAILS_STATES.FLIGHT: return "Flight Selection";
        case DETAILS_STATES.PASSENGERS: return "Passenger Selection";
        case DETAILS_STATES.ELIGIBILITY: return "Eligibility";
        case DETAILS_STATES.CONFIRM: return "Payout";
        case DETAILS_STATES.COMPLETE: return "Confirm";
        default: return "";
    }
}

export const PAYMENT_STATES = Object.freeze({
    AIRLINE_CREDIT: 0,
    AIRLINE_POINTS: 1,
    ELECTRONIC_FUNDS: 2,
    VENMO: 3,
    UNDECIDED: 4
});

export const BAG_STATUS = Object.freeze({
    DELIVERED: 0,
    PENDING: 1,
    LATE: 2,
})

export const FLIGHT_STATUS = Object.freeze({
    UNAFFECTED: 0,
    CANCELED: 1,
    SHORT_DELAY: 2,
    LONG_DELAY: 3,
    WEATHER: 4
});

export const CLAIM_STATES = Object.freeze({
    CLAIM: 0,
    DISPUTE: 1,
    RESOLUTION: 2,
    LOADING: 3
});

export const CLAIM_STATUS = Object.freeze({
    ACCEPTED: "Accepted",
    DISPUTED: "Disputed",
    REJECTED: "Rejected"
});

export const CLAIM_ERRORS = Object.freeze({
    DATE: 0,
    LOCATION: 1,
    RECEIPT: 2,
    COST: 3,
    TOTAL_COST: 4,
    ALCOHOL: 5
});

export const ISSUE_CATEGORIES = Object.freeze({
    DISRUPTION_REIMBURSEMENT: "Disruption Reimbursement",
    BAG_DAMAGE: "Bag Damage",
    FEEDBACK: "Feedback",
    LOST_BAG: "Lost Baggage",
    PASSENGER_RIGHTS: "Passenger Rights Compensation",
    REFUND_REQUEUST: "Refund Request",
    BAG_SEAT_WIFI: "Bag, Seat, or WiFi Refund",
    INVOLUNTARY_DENIED: "In-Voluntary Denied Boarding",
    VOLUNTARY_DENIED: "Voluntary Denied Boarding"
});

export const LOADING_LOCS = Object.freeze([
    {
        top: "120px", width: "796px", left: "79px"
    },
    {
        top: "150px", width: "606px", left: "162px"
    },
    {
        top: "180px", width: "796px", left: "79px"
    }
]);

export const LOADING_LOCS_SMALL = Object.freeze([
    {
        top: "220px", right: "44px", left: "44px",
    },
    {
        top: "250px", right: "78px", left: "78px",
    },
    {
        top: "280px", right: "44px", left: "44px",
    },
    {
        top: "310px", right: "44px", left: "44px",
    },
    {
        top: "340px", right: "78px", left: "78px",
    },
    {
        top: "370px", right: "44px", left: "44px",
    }
]);

export const DEFAULT_SCENARIO = "S2V23G";
export const DEMO_SCENARIOS = Object.freeze({
    "ABCDE": { // Std reimbursement flow 
        flights: [
            {
                num: "GA 356",
                time: "12pm - 2pm EST",
                routeStart: "Pittsburgh",
                routeStartAbr: "PIT",
                routeEnd: "Baltimore",
                routeEndAbr: "BWI",
                date: "1/12/24",
                status: FLIGHT_STATUS.CANCELED
            },
            {
                num: "GA 357", 
                time: "2pm - 3:30pm EST",
                routeStart: "Baltimore",
                routeStartAbr: "BWI",
                routeEnd: "Pittsburgh",
                routeEndAbr: "PIT",
                date: "1/13/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        passengers: ["Richard Lane", "Jessica Lane", "Jackson Lane"],
        bags: [
            {
                id: "874234321",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "874234322",
                status: BAG_STATUS.PENDING,
                lastUpdate: "1/13, 3:45pm: Loaded off flight, Pittsburgh"
            }
        ],
        loyalty: true,
        airlineInfo: {
            name: "American Airlines",
            policy: {
                hotelReimbursement: 200,
                transportationReimbursement: 50,
                mealReimbursement: 20
            }
        }
    },
    "3ERWT6": { // No available reimbursements
        flights: [
            {
                num: "GA 234",
                time: "8am CST - 9:30am MST",
                routeStart: "Chicago",
                routeStartAbr: "ORD",
                routeEnd: "Denver",
                routeEndAbr: "DEN",
                date: "3/12/24",
                status: FLIGHT_STATUS.WEATHER
            },
            {
                num: "GA 235",
                time: "7pm MST - 10:30pm CST",
                routeStart: "Denver",
                routeStartAbr: "DEN",
                routeEnd: "Chicago",
                routeEndAbr: "ORD",
                date: "3/25/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "817294328",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "817294329",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (1 Day Late)"
            }
        ],
        passengers: ["Nathan Barker"],
        loyalty: false,
        airlineInfo: {
            name: "Delta Airlines",
            policy: {
                hotelReimbursement: 150,
                transportationReimbursement: 90,
                mealReimbursement: 15
            }
        }
    },
    "PXG5YR": { // Dispute flow
        flights: [
            {
                num: "GA 178",
                time: "5pm PST - 8pm PST",
                routeStart: "Seattle",
                routeStartAbr: "SEA",
                routeEnd: "Los Angeles",
                routeEndAbr: "LAX",
                date: "5/2/24",
                status: FLIGHT_STATUS.UNAFFECTED
            },
            {
                num: "GA 179",
                time: "6pm PST - 9pm PST",
                routeStart: "Los Angeles",
                routeStartAbr: "LAX",
                routeEnd: "Seattle",
                routeEndAbr: "SEA",
                date: "5/7/24",
                status: FLIGHT_STATUS.CANCELED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "5/2, 8:13pm: Loaded off flight, Los Angeles"
            },
            {
                id: "482949824",
                status: BAG_STATUS.DELIVERED,
                lastUpdate: "5/2, 8:15pm: Loaded off flight, Los Angeles"
            }
        ],
        passengers: ["Victoria Park"],
        loyalty: true,
        airlineInfo: {
            name: "Delta Airlines",
            policy: {
                hotelReimbursement: 150,
                transportationReimbursement: 90,
                mealReimbursement: 15
            }
        }
    },
    "39F66G": {
        flights: [
            {
                num: "GA 100",
                time: "6pm EST - 6am BST",
                routeStart: "New York",
                routeStartAbr: "JFK",
                routeEnd: "London",
                routeEndAbr: "LHR",
                date: "7/12/24",
                status: FLIGHT_STATUS.SHORT_DELAY
            },
            {
                num: "GA 101",
                time: "12pm BST - 4:30pm EST",
                routeStart: "London",
                routeStartAbr: "LHR",
                routeEnd: "New York",
                routeEndAbr: "JFK",
                date: "7/14/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.PENDING,
                lastUpdate: "Delivered to Belt"
            },
            {
                id: "482949824",
                status: BAG_STATUS.PENDING,
                lastUpdate: "Delivered to Belt"
            }
        ],
        passengers: ["Alex Brandt"],
        loyalty: false,
        receiptApproval: "stern"
    },
    "E0DFM9": {
        flights: [
            {
                num: "GA 110",
                time: "6:30am BST - 10am CEST",
                routeStart: "London",
                routeStartAbr: "LHR",
                routeEnd: "Rome",
                routeEndAbr: "FCO",
                date: "7/22/24",
                status: FLIGHT_STATUS.LONG_DELAY
            },
            {
                num: "GA 111",
                time: "1pm CEST - 2:30 BST",
                routeStart: "Rome",
                routeStartAbr: "FCO",
                routeEnd: "London",
                routeEndAbr: "LHR",
                date: "7/24/24",
                status: FLIGHT_STATUS.UNAFFECTED
            }
        ],
        bags: [
            {
                id: "482949823",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (2 Days Late)"
            },
            {
                id: "482949824",
                status: BAG_STATUS.LATE,
                lastUpdate: "Delivered to Home (2 Days Late)"
            }
        ],
        passengers: ["Molly Santos"],
        loyalty: true,
        receiptApproval: "light"
    }
});

export const BASE_STATE = Object.freeze({
    menuState: MENU_STATES.LOADING,
    detailsState: DETAILS_STATES.LOOKUP,
    detailsStatesVisited: [true, false, false, false, false, false, false, false, false, false, false, false, false],
    searchTerm: "",
    baseData: {},
    data: {
        category: ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT,
        confirmationCode: "",
        flights: [],
        bags: [],
        containsEligibleFlights: false,
        passengers: [],
        claims: [],
        bagClaims: [],
        paymentFormSelected: PAYMENT_STATES.UNDECIDED,
        paymentForm: PAYMENT_STATES.UNDECIDED,
        paymentAmount: "",
        paymentDetails: "",
        bagMatches: [],
        bagMatch: -1
    }
})
export let seenFeedbackCategories = [];

export const FEEDBACK_RESPONSE = Object.freeze({
    TIER0: 0,
    TIER1: 1,
    TIER2: 2,
    REDIRECT_TO_GANDER: 3,
    LIVE_SUPPORT: 4,
    OTHER: 5,
    POSITIVE: 6
})

export const FEEDBACK_CATEGORIES = Object.freeze({
    "Food & Beverage Service Failure": {"issueText": "issues with our on-board service", "department": "on-board catering", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Boarding Process": {"issueText": "issues with our boarding process", "department": "airport experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "In-Operable Lavatory": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Missed Connection": {"issueText": "issues with completing your journey", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Poor Phone or Chat Support Experience": {"issueText": "issues with getting support", "department": "customer support", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "App or Website Issue": {"issueText": "issues with our digital products", "department": "technology", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Pricing Discrepancy": {"issueText": "issues with our fares", "department": "pricing", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Cabin Temperature": {"issueText": "discomfort onboard our aircraft", "department": "aircraft experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Aircraft Cleanliness": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "In-Flight Entertainment failure": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "No Seat Recline": {"issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Flight Quality & Turbulance": {"issueText": "issues onboard our aircraft", "department": "chief pilot", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Delay & Cancellation Impact": {"issueText": "issues with completing your journey", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Fellow Passenger Behavior": {"issueText": "issues onboard our aircraft", "department": "customer experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Lounge Crowding": {"issueText": "issues in our lounges", "department": "lounge experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Flight Attendant Behavior": {"issueText": "issues with our crew", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Check-In Attendant Behavior": {"issueText": "issues with our crerw", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    "Baggage Complaints": {"issueText": "issues with your bags", "department": "bag services", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.REDIRECT_TO_GANDER},
    "Lack of Loyalty Recognition": {"issueText": "issues with your loyalty experience", "department": "Gander rewards", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Sickness Caused by Airline": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Bodily Harm Caused by Airline": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Mis-Handling of Important Item": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Wheelchair or Assistive Device Failure": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Unaccompanied Minor Failure": {"issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Sick or Elderly Individual Failure": {"issueText": "issues with your loved one's journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Premium Cabin Experience": {"issueText": "issues with your business class experience", "department": "premium services", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    "Extraneous Fees": {"issueText": "issues with our fees", "department": "customer policies", "comp": -1, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    "Wheelchair Not Available": {"issueText": "issues with your wheelchair", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    "Compliment": {"issueText": "", "department": "employee recognition", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.POSITIVE},
    "Other": {"issueText": "issues with your journey with us", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.OTHER}
});
/*
export const FEEDBACK_CATEGORIES = Object.freeze({
    FOOD_BEV: {"id": "Food & Beverage Service Failure", "issueText": "issues with our on-board service", "department": "on-board catering", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    BOARDING: {"id": "Boarding Process", "issueText": "issues with our boarding process", "department": "airport experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    LAVATORY: {"id": "In-Operable Lavatory", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    CONNECTION: {"id": "Missed Connection", "issueText": "issues with completing your journey", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    SUPPORT: {"id": "Poor Phone or Chat Support Experience", "issueText": "issues with getting support", "department": "customer support", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    APP_WEB: {"id": "App or Website Issue", "issueText": "issues with our digital products", "department": "technology", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    PRICING: {"id": "Pricing Discrepancy", "issueText": "issues with our fares", "department": "pricing", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    TEMPERATURE: {"id": "Cabin Temperature", "issueText": "discomfort onboard our aircraft", "department": "aircraft experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    CLEANLINESS: {"id": "Aircraft Cleanliness", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    IFE: {"id": "In-Flight Entertainment failure", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    SEAT: {"id": "No Seat Recline", "issueText": "issues onboard our aircraft", "department": "maintenance", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    TURBULANCE: {"id": "Flight Quality & Turbulance", "issueText": "issues onboard our aircraft", "department": "chief pilot", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    DELAY_CANCEL: {"id": "Delay & Cancellation Impact", "issueText": "issues with completing your journey", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    PASSENGER: {"id": "Fellow Passenger Behavior", "issueText": "issues onboard our aircraft", "department": "customer experience", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    LOUNGE: {"id": "Lounge Crowding", "issueText": "issues in our lounges", "department": "lounge experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    ATTENDANT: {"id": "Flight Attendant Behavior", "issueText": "issues with our crew", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    CHECK_IN: {"id": "Check-In Attendant Behavior", "issueText": "issues with our crerw", "department": "customer experience", "comp": 5000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER1},
    BAGGAGE: {"id": "Baggage Complaints", "issueText": "issues with your bags", "department": "bag services", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.REDIRECT_TO_GANDER},
    LOYALTY: {"id": "Lack of Loyalty Recognition", "issueText": "issues with your loyalty experience", "department": "Gander rewards", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.TIER0},
    SICKNESS: {"id": "Sickness Caused by Airline", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    HARM: {"id": "Bodily Harm Caused by Airline", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    IMPORTANT_ITEM: {"id": "Mis-Handling of Important Item", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    WHEELCHAIR_ERR: {"id": "Wheelchair or Assistive Device Failure", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    MINOR: {"id": "Unaccompanied Minor Failure", "issueText": "issues with your journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    ELDERLY: {"id": "Sick or Elderly Individual Failure", "issueText": "issues with your loved one's journey with us", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    PREMIUM_CABIN: {"id": "Premium Cabin Experience", "issueText": "issues with your business class experience", "department": "premium services", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER0},
    FEES: {"id": "Extraneous Fees", "issueText": "issues with our fees", "department": "customer policies", "comp": -1, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.LIVE_SUPPORT},
    WHEELCHAIR_NA: {"id": "Wheelchair Not Available", "issueText": "issues with your wheelchair", "department": "customer incidents", "comp": 10000, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.TIER2},
    COMPLIMENT: {"id": "Compliment", "issueText": "", "department": "", "comp": 0, "goldEligible": false, "escalation": FEEDBACK_RESPONSE.POSITIVE},
    OTHER: {"id": "Other", "issueText": "issues with your journey with us", "department": "customer experience", "comp": 0, "goldEligible": true, "escalation": FEEDBACK_RESPONSE.OTHER}
});
*/

export function baggage_scope(item) {
    return `You are a knowledgable customer service agent for Gander Airways.
        You are tasked with determining whether a customer's reimbursement claim for a specific item is reasonable.
        Output for scope is a score of 0 to 100 where 0 is a fairly egregious claim and 100 is a claim that is obviously in scope.
        Here is the item in question:
        <item>
        ${item}
        </item>
        Please return only with the score, nothing else.
        `
}
