import React from 'react';
import styles from './Flight.module.css';
import globalStyles from '../global.module.css';
import ScaleText from "react-scale-text";
import { DETAILS_STATES, FLIGHT_STATUS, LOADING_LOCS, TrimList, ISSUE_CATEGORIES } from '../util';

import ganderAir from './images/ganderair.png';
import breezeAir from './images/breezeLogoSmall.png';
import checkmark from './images/checkmark.svg';
import chevWhite from './images/chevWhite.svg';
import chevGrey from './images/chevGrey.svg';
import arrow from './images/arrow.svg';
import breezeLogoSquare from './images/breezeLogoSquare.png';

class FlightStatus extends React.Component {
    render() {
        if (this.props.status == FLIGHT_STATUS.UNAFFECTED) {
            return (<div />);
        }
        
        let text = "";
        if (this.props.status == FLIGHT_STATUS.CANCELED || this.props.status == FLIGHT_STATUS.WEATHER) {
            text = "CANCELED";
        }
        else if (this.props.status == FLIGHT_STATUS.SHORT_DELAY) {
            text = "4H DELAY";
        }
        else if (this.props.status == FLIGHT_STATUS.LONG_DELAY) {
            text = "9H DELAY";
        }
        if (this.props.small) {
            return (
                <div id={styles.canceledSelected} style={{left: `${this.props.count == 1 ? 198 : 290}px`}}>
                    <div id={styles.canceledText} style={{fontSize: "10px"}}>{text}</div>
                </div>
            )
        }
        return (
            <div id={styles.canceled}>
                <div id={styles.canceledText}>{text}</div>
            </div>
        );
    }
}

export class IndividualFlight extends React.Component {
    render() {
        let flightInfo = this.props.baseData.flights[this.props.index];
        if (window.innerWidth < 1005) {
            let classOuter = styles.mobileFlight;
            if (this.props.data.flights[this.props.index]) {
                classOuter = `${styles.mobileFlight} ${styles.flightSelected}`;
            }
            return (
                <div className={classOuter} style={{top: `${20 + 120 * this.props.index}px`}} onClick={() => this.props.changeFlights(this.props.index)}>
                    {
                        this.props.data.flights[this.props.index] ? 
                        <div className={styles.selected}>
                            <img src={checkmark} className={styles.selectedImg} />
                        </div>
                        :
                        <div className={styles.selector} />
                    }
                    <img src={breezeLogoSquare} className={styles.mobileLogo} />
                    <div className={styles.mobileFlightNo}>{flightInfo.num}</div>
                    <div id={styles.mobileFlightInfo}>
                        <div className={styles.mobileRoute}>{flightInfo.routeStart} ({flightInfo.routeStartAbr}) - {flightInfo.routeEnd} ({flightInfo.routeEndAbr})</div>
                        <div className={styles.mobileDatetime}>{flightInfo.time} | {flightInfo.date}</div>
                    </div>
                </div>
            );
        }
        let classOuter = styles.flight;
        if (this.props.data.flights[this.props.index]) {
            classOuter = `${styles.flight} ${styles.flightSelected}`;
        }
        return (
            <div className={classOuter} style={{top: `${81 + 103 * this.props.index}px`}} onClick={() => this.props.changeFlights(this.props.index)}>
                {
                    this.props.data.flights[this.props.index] ? 
                    <div className={styles.selected}>
                        <img src={checkmark} className={styles.selectedImg} />
                    </div>
                    :
                    <div className={styles.selector} />
                }
                <img src={breezeLogoSquare} className={styles.logo} />
                <div className={styles.flightNo}>{flightInfo.num}</div>
                <div className={styles.route}>{flightInfo.routeStart} ({flightInfo.routeStartAbr}) - {flightInfo.routeEnd} ({flightInfo.routeEndAbr})</div>
                <div className={styles.datetime}>{flightInfo.time} | {flightInfo.date}</div>
                <FlightStatus status={flightInfo.status} small={false} />
            </div>
        );
    }
}

class Flight extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE || this.props.data.category == ISSUE_CATEGORIES.LOST_BAG) {
            if (this.props.state == DETAILS_STATES.FLIGHT) {
                this.props.updateState(DETAILS_STATES.BAGS);
            }
            return (<div />);
        }
        else if (window.innerWidth < 1005) {
            return (<div />);
        }
        else if (this.props.state == DETAILS_STATES.FLIGHT) {
            let allFlightsSelected = true;
            let anyFlightsSelected = false;
            for (let i = 0; i < this.props.data.flights.length; i++) {
                if (this.props.data.flights[i] == false) {
                    allFlightsSelected = false;
                }
                else {
                    anyFlightsSelected = true;
                }
            }
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: "381px", marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Pick A Flight</div>
                    {
                        this.props.data.flights.map((_, index) => 
                            <IndividualFlight 
                                key={index} 
                                index={index} 
                                data={this.props.data} 
                                baseData={this.props.baseData} 
                                changeFlights={this.props.changeFlights} />)
                    }
                    <div id={styles.selectAll} className={allFlightsSelected ? styles.flightSelected : ""} onClick={() => this.props.changeFlights(this.props.data.flights.length)}>
                        {
                            allFlightsSelected ? 
                            <div className={styles.selected} style={{top: "16px"}}>
                                <img src={checkmark} className={styles.selectedImg} />
                            </div>
                            :
                            <div className={styles.selector} style={{top: "16px"}} />
                        }
                        <div id={styles.selectAllText}>All Flights In Your Trip</div>
                    </div>
                </div>
                {
                    anyFlightsSelected ? 
                    <div className={globalStyles.continue} onClick={() => this.props.updateState(DETAILS_STATES.PASSENGERS)}>
                        <div id={styles.selectText} style={{color: "white", left: "52px"}}>Select Passengers</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Select a Flight to Continue</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            );
        }
        else if (this.props.state == DETAILS_STATES.LOOKUP && !this.props.visited[DETAILS_STATES.FLIGHT]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Pick A Flight</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        else {
            let titleText = "";
            let subtitleText = "";
            let flights = [];
            for (let i = 0; i < this.props.data.flights.length; i++) {
                if (this.props.data.flights[i]) {
                    titleText += `${this.props.baseData.flights[i].num}, `;
                    flights.push(this.props.baseData.flights[i]);
                }
            }
            titleText = TrimList(titleText);

            if (flights.length == 1) {
                subtitleText = `${flights[0].date} | ${flights[0].routeStart} (${flights[0].routeStartAbr}) - ${flights[0].routeEnd} (${flights[0].routeEndAbr})`;
            }
            else if (flights.length == 2) {
                let back = flights.length - 1;
                subtitleText = `${flights[0].date} - ${flights[back].date} | ${flights[0].routeStartAbr} - ${flights[0].routeEndAbr}, ${flights[back].routeStartAbr} - ${flights[back].routeEndAbr}`;
            }
            else if (flights.length == 3) {
                subtitleText = "3 or more flights selected"
            }
            let status = FLIGHT_STATUS.UNAFFECTED;
            for (let i = 0; i < this.props.data.flights.length; i++) {
                if (this.props.data.flights[i] && this.props.baseData.flights[i].status != FLIGHT_STATUS.UNAFFECTED) {
                    status = this.props.baseData.flights[i].status;
                }
            }
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                    <img src={breezeAir} id={styles.logoSelected} />
                    <div id={styles.flightTitleSelected}>{titleText}</div>
                    <div id={styles.flightSubtitleSelected}>{subtitleText}</div>
                    <FlightStatus status={status} count={flights.length} small={true} />
                    <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.FLIGHT)}>
                        <img src={arrow} className={globalStyles.changeTripImg} />
                        <div className={globalStyles.changeTripText}>Change Selection</div>
                    </div>
                </div>
            )
        }
    }
}

export default Flight;