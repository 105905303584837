import React from 'react';
import styles from './Eligibility.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES, LOADING_LOCS, CLAIM_STATUS, ISSUE_CATEGORIES } from '../util';

import arrow from './images/arrow.svg';
import hotel from './images/hotel.svg';
import meals from '../NotForm/images/meals.svg';
import transportation from './images/transportation.svg';
import chevWhite from './images/chevWhite.svg';
import chevGrey from './images/chevGrey.svg';
import confetti from './images/confetti.svg';
import info from './images/info.svg';
import cancel from './images/close.png';
import noEligible from './images/noEligible.svg';
import map from './images/map.png';
import swVisa from './images/swVisa.png';
import visa from './images/visa.png';
import amex from './images/amex.png';
import dotLogo from './images/dotLogo.png';
import chevBlue from './images/chevBlueUp.png';

class Eligibility extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAcceptedClaims: false,
            showDisputedClaims: false,
            showRejectedClaims: false
        };
    }

    render() {
        if (this.props.data.category == ISSUE_CATEGORIES.FEEDBACK || this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE || this.props.data.category == ISSUE_CATEGORIES.LOST_BAG || this.props.data.category == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
            if (this.props.state == DETAILS_STATES.ELIGIBILITY) {
                if (this.props.data.category == ISSUE_CATEGORIES.FEEDBACK) {
                    this.props.updateState(DETAILS_STATES.FEEDBACK);
                }
                else if (this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE || this.props.data.category == ISSUE_CATEGORIES.LOST_BAG) {
                    this.props.updateState(DETAILS_STATES.BAGS);
                }
                else if (this.props.data.category == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
                    this.props.updateState(DETAILS_STATES.CONFIRM);
                }
            }
            return (<div />);
        }
        else if (window.innerWidth < 1005 && this.props.state != DETAILS_STATES.ELIGIBILITY) {
            return (<div />);
        }
        else if (this.props.state < DETAILS_STATES.ELIGIBILITY && !this.props.visited[DETAILS_STATES.ELIGIBILITY]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Eligibility</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        else if (this.props.state == DETAILS_STATES.ELIGIBILITY) {
            if (this.props.data.containsEligibleFlights) {
                if (this.props.data.category == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
                    let claim = {
                        "status": CLAIM_STATUS.ACCEPTED,
                        "amount": 400,
                        "date": "",
                        "vendor": ""
                    };
                    this.props.addClaim(claim);
                    this.props.updateState(DETAILS_STATES.CONFIRM);
                }
                this.props.data.claims = [{"status": CLAIM_STATUS.ACCEPTED, "amount": 100}];
                let height = 580;
                if (this.props.data.claims.length > 0) {
                    height = 1031;
                }
                let totalAccepted = 0;
                let acceptedClaims = [];
                let totalRejected = 0;
                let rejectedClaims = [];
                let totalDisputed = 0;
                let disputedClaims = [];
                for (let i = 0; i < this.props.data.claims.length; i++) {
                    if (this.props.data.claims[i]["status"] == CLAIM_STATUS.ACCEPTED) {
                        totalAccepted += this.props.data.claims[i]["amount"];
                        acceptedClaims.push(this.props.data.claims[i]);
                    }
                    else if (this.props.data.claims[i]["status"] == CLAIM_STATUS.REJECTED) {
                        totalRejected += this.props.data.claims[i]["amount"];
                        rejectedClaims.push(this.props.data.claims[i]);
                    }
                    else if (this.props.data.claims[i]["status"] == CLAIM_STATUS.DISPUTED) {
                        totalDisputed += this.props.data.claims[i]["amount"];
                        disputedClaims.push(this.props.data.claims[i]);
                    }
                }
                totalAccepted = 100;

                /* // Dummy data
                acceptedClaims.push({
                    "status": "Accepted",
                    "amount": 70,
                    "date": "12/12/2024",
                    "vendor": "Marriott"
                });
                acceptedClaims.push({
                    "status": "Accepted",
                    "amount": 120,
                    "date": "12/13/2024",
                    "vendor": "Avis"
                });
                rejectedClaims.push({
                    "status": "Rejected",
                    "amount": 190,
                    "date": "12/13/2024",
                    "vendor": "Best Buy"
                });
                totalAccepted += 70 + 120;
                totalRejected += 190;
                */ // End dummy data

                let statusColors = {
                    "Accepted": "#84C631",
                    "Disputed": "#EEE741",
                    "Rejected": "rgba(255, 35, 35, .7)"
                };
                if (window.innerWidth < 1005) {
                    return (
                        <div>
                        <div className={globalStyles.mobileStepOuterStack} style={{height: `${442 + (this.state.showAcceptedClaims ? acceptedClaims.length * 52 : 0) + (this.state.showDisputedClaims ? disputedClaims.length * 52 : 0) + (this.state.showRejectedClaims ? rejectedClaims.length * 52 : 0)}px`, width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}}>
                            <img id={styles.subtitleImgMobile} src={confetti} />
                            <div id={styles.subtitleMobile}>Good News!</div>
                            <div id={styles.subsubtitleMobile}>Your flight was canceled <b>due to a lack of aircraft availability</b>. According to our customer care policy, we are able to cover reasonable expenses incurred during the disruption up to <b>$150</b>.</div>
                            <div id={styles.progressBarOuter}>
                                <div id={styles.progressBarInner} style={{width: `${Math.min(100, (totalAccepted / 150) * 100)}%`}} />
                            </div>
                            <div className={styles.progressBarEndcap} style={{left: "17px"}} />
                            <div className={styles.progressBarEndcap} style={{right: "17px"}} />
                            <div id={styles.progressBarTextLeft}>$0</div>
                            <div id={styles.progressBarTextRight}>$150</div>
                            <div id={styles.claimsOuterMobile}>
                                <div className={styles.claimBarMobile}>
                                    <div className={styles.claimBarText}>Accepted Claims (${totalAccepted.toFixed(2)})</div>
                                    <img src={chevBlue} className={styles.claimBarImg} onClick={() => this.setState({showAcceptedClaims: !this.state.showAcceptedClaims})} style={{transform: !this.state.showAcceptedClaims ? "rotate(180deg)" : "rotate(0deg)"}}/>
                                </div>
                                {
                                    this.state.showAcceptedClaims && totalAccepted > 0 ?
                                    <div>
                                        {
                                            acceptedClaims.map((claim, index) => (
                                                <div className={styles.claimEntryMobile} key={index}>
                                                    <div className={styles.claimAmountMobile}>${claim["amount"].toFixed(0)}</div>
                                                    <div className={styles.claimNameMobile}>{claim["date"]} - {claim["vendor"]}</div>
                                                    <img src={cancel} className={styles.cancelClaimImgMobile} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div />
                                }
                                <div className={styles.claimBarMobile}>
                                    <div className={styles.claimBarText}>Disputed Claims (${totalDisputed.toFixed(2)})</div>
                                    <img src={chevBlue} className={styles.claimBarImg} onClick={() => this.setState({showDisputedClaims: !this.state.showDisputedClaims})} style={{transform: !this.state.showDisputedClaims ? "rotate(180deg)" : "rotate(0deg)"}}/>
                                </div>
                                {
                                    this.state.showDisputedClaims && totalDisputed > 0 ?
                                    <div>
                                        {
                                            disputedClaims.map((claim, index) => (
                                                <div className={styles.claimEntryMobile} key={index}>
                                                    <div className={styles.claimAmountMobile}>${claim["amount"].toFixed(0)}</div>
                                                    <div className={styles.claimNameMobile}>{claim["date"]} - {claim["vendor"]}</div>
                                                    <img src={cancel} className={styles.cancelClaimImgMobile} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div />
                                }
                                <div className={styles.claimBarMobile}>
                                    <div className={styles.claimBarText}>Rejected Claims (${totalRejected.toFixed(2)})</div>
                                    <img src={chevBlue} className={styles.claimBarImg} onClick={() => this.setState({showRejectedClaims: !this.state.showRejectedClaims})} style={{transform: !this.state.showRejectedClaims ? "rotate(180deg)" : "rotate(0deg)"}}/>
                                </div>
                                {
                                    this.state.showRejectedClaims && totalRejected > 0 ?
                                    <div>
                                        {
                                            rejectedClaims.map((claim, index) => (
                                                <div className={styles.claimEntryMobile} key={index}>
                                                    <div className={styles.claimAmountMobile}>${claim["amount"].toFixed(0)}</div>
                                                    <div className={styles.claimNameMobile}>{claim["date"]} - {claim["vendor"]}</div>
                                                    <img src={cancel} className={styles.cancelClaimImgMobile} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div />
                                }
                            </div>
                        </div>
                        <div className={styles.accomodationTypeMobile} style={{width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}}>
                            <img src={hotel} className={styles.accomodationImg} />
                            <div className={styles.accomodationText}>Hotel Accomodation</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Hotel Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationTypeMobile} style={{width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}}>
                            <img src={transportation} className={styles.accomodationImg} />
                            <div className={styles.accomodationText}>Transportation</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Transportation Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationTypeMobile} style={{width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}}>
                            <img src={meals} className={styles.accomodationImg} />
                            <div className={styles.accomodationText}>Meals</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Meals Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div id={styles.claimOtherMobile} style={{width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}} onClick={() => this.props.triggerPopup("Other Reimbursement")}>
                            <div id={styles.claimOtherText}>Claim Other</div>
                        </div>
                        </div>
                    );
                }
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: `${height}px`, marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Eligibility</div>
                        <div id={styles.subtitleDiv}>
                            <div id={styles.subtitle}>Good News!</div>
                            <div id={styles.subsubtitle}>Your flight was canceled <b>due to a lack of aircraft availability</b> within Breeze's control. According to our<br/>customer care policy, we are able to cover reasonable expenses incurred during the disruption.</div>
                            <img src={confetti} id={styles.subtitleImg} />
                        </div>
                        <div className={styles.accomodationType} style={{left: "29px"}}>
                            <img src={hotel} className={styles.accomodationImg} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Hotel Accomodation</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Hotel Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>                    </div>
                        <div className={styles.accomodationType} style={{right: "29px"}}>
                            <img src={transportation} className={styles.accomodationImg} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Transportation</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Transportation Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationType} style={{left: "336px"}}>
                            <img src={meals} className={styles.accomodationImg} style={{width: "60px", left: "110px", top: "30px"}} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Meals</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Meals Reimbursement")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div id={styles.claimOther} onClick={() => this.props.triggerPopup("Other Reimbursement")}>
                            <div id={styles.claimOtherText}>Claim Other</div>
                        </div>
                        {
                            this.props.data.claims.length > 0 ? 
                            <div id={styles.claimsOuter}>
                                <div id={styles.yourClaims}>Your Claims</div>
                                {/*
                                <div className={styles.totalsLeft} style={{left: "328px", backgroundColor: "#84C631"}}>Accepted</div>
                                <div className={styles.totalsRight} style={{left: "413px", backgroundColor: "rgba(132, 198, 49, .3)"}}>${totalAccepted.toFixed(2)}</div>
                                <div className={styles.totalsLeft} style={{left: "510px", backgroundColor: "#EEE741"}}>Disputed</div>
                                <div className={styles.totalsRight} style={{left: "595px", backgroundColor: "rgba(238, 231, 65, .3)"}}>${totalDisputed.toFixed(2)}</div>
                                <div className={styles.totalsLeft} style={{left: "692px", backgroundColor: "rgba(255, 35, 35, .7"}}>Rejected</div>
                                <div className={styles.totalsRight} style={{left: "777px", backgroundColor: "rgba(255, 128, 98, .3)"}}>${totalRejected.toFixed(2)}</div>
                                */}
                                <div className={styles.progressBarVert} style={{left: "220px"}} />
                                <div className={styles.progressBarVert} style={{right: "30px"}} />
                                <div id={styles.progressBarLText}>$0</div>
                                <div id={styles.progressBarRText}>$150</div>
                                <div id={styles.progressBarOuter}>
                                    <div id={styles.progressBar} style={{width: `${totalAccepted * 100 / 150}%`}}>
                                        <div id={styles.progressBarText}>${totalAccepted.toFixed(0)}</div>
                                    </div>
                                </div>
                                <div className={styles.claimsHeaders} style={{left: "28px"}}>Status</div>
                                <div className={styles.claimsHeaders} style={{left: "138px"}}>Amount</div>
                                <div className={styles.claimsHeaders} style={{left: "230px"}}>Name</div>
                                <div id={styles.topLine}>
                                    {
                                        this.props.data.claims.map((claim, index) => (
                                            <div className={styles.claimEntry} style={{top: `${index * 47}px`}} key={index}>
                                                <div className={styles.status} style={{backgroundColor: statusColors[claim["status"]]}}>{claim["status"]}</div>
                                                <div className={styles.claimAmount}>${claim["amount"].toFixed(2)}</div>
                                                <div className={styles.claimName}>{claim["date"]} - {claim["vendor"]}</div>
                                                <div className={styles.cancelClaim} onClick={() => this.props.removeClaim(index)}>
                                                    <img src={cancel} className={styles.cancelClaimImg} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <div />
                        }
                        <div id={styles.moreInfo}><u>Not what you were looking for?</u></div>
                    </div>
                    {
                        this.props.data.claims.length > 0 ? 
                        <div className={globalStyles.continue} onClick={() => this.props.updateState(DETAILS_STATES.CONFIRM)}>
                            <div id={styles.selectText} style={{color: "white", left: "65px"}}>Confirm Claims</div>
                            <img src={chevWhite} id={styles.selectImg} />
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Add Claims To Continue</div>
                            <img src={chevGrey} id={styles.selectImg} />
                        </div>
                    }
                    </div>
                );
            }
            else {
                let isPassengerRights = this.props.data.category == ISSUE_CATEGORIES.PASSENGER_RIGHTS;
                let firstIndex = 0;
                for (let i = 0; i < this.props.data.flights.length; i++) {
                    if (this.props.data.flights[i]) {
                        firstIndex = i;
                        break;
                    }
                }
                return (
                    <div className={globalStyles.stepOuter} style={{height: "636px", marginBottom: "15px"}}>
                        <div className={globalStyles.stepTitle}>Eligibility</div>
                        <div id={styles.subtitleDiv}>
                            <div id={styles.subtitle}>No Expenses Eligible</div>
                            <div id={styles.subsubtitle}>Your flight was cancelled due to <b>severe weather en-route to your destination</b>. Since the disruption was beyond Breeze's control, your expenses are not eligible for{isPassengerRights ? " Passenger Rights" : ""} compensation.</div>
                            <img src={noEligible} id={styles.subtitleImg} />
                        </div>
                        <div className={styles.noEligibleDiv} style={{left: "31px"}}>
                            <img src={map} id={styles.mapHeader} />
                            <div id={styles.weatherTitle}>En-Route Weather</div>
                            {
                                isPassengerRights ? 
                                <div id={styles.weatherText}>We understand the impact that delays can have on your travel plans. However, <b>circumstances like weather that are beyond our control</b> and directly impact our ability to transport you to your destination safely. Because this was a factor outside of Breeze's control, we are unable to offer you any regulatory compensation.</div>
                                :
                                <div>
                                    <div id={styles.weatherText}>We understand how frustrating it can be to be delayed to weather when the skies are blue are your point of departure. However, on <b>{this.props.baseData.flights[firstIndex].date}</b> the National Airspace System Delay program asked Breeze to limit the number of aircraft in the sky due to weather across the country and this is why we cancelled <b>{this.props.baseData.flights[firstIndex].num}</b>.</div>
                                    <div id={styles.learnMore}>Learn More</div>
                                </div>
                            }
                        </div>
                        <div className={styles.noEligibleDiv} style={{right: "31px"}}>
                            {
                                isPassengerRights ? 
                                <div>
                                    <img src={dotLogo} id={styles.dotLogo} />
                                    <div id={styles.cardCoverageTitle}>Know Your Rights</div>
                                    <div id={styles.cardCoverageText}>This flight falls under the authority of the <b>U.K Department for Transport</b> regulations. Breeze complys strictly within these guidelines and we encourage you to educate yourself below.</div>
                                    <div id={styles.checkEligibility}>Read More</div>
                                </div>
                                :
                                <div>
                                    <img src={swVisa} className={styles.cardHeader} style={{left: "62px"}} />
                                    <img src={visa} className={styles.cardHeader} style={{left: "169px"}} />
                                    <div id={styles.cardHeaderAmex}>
                                        <img src={amex} id={styles.cardHeaderImgAmex} />
                                    </div>
                                    <div id={styles.cardCoverageTitle}>You May Be Covered</div>
                                    <div id={styles.cardCoverageText}>Certain credit cards cover flight disruptions due to weather. We partner with Breeze to help you claim compensation and verify your disruption</div>
                                    <div id={styles.checkEligibility} onClick={this.props.triggerCreditCardPopup}>Check Eligibility</div>
                                </div>
                            }
                            
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Eligibility</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.ELIGIBILITY)}>
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                    <img src={arrow} className={globalStyles.changeTripImg} />
                </div>
            </div>
        );
    }
}

export default Eligibility;