// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Outfit-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SF-Pro.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Font imports */
@font-face {
    font-family: "Outfit";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: "SF Pro";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
/**/

.Footer_mobileFooter__tJqeI {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 45px;
    cursor: pointer;
}
#Footer_mobileFooterActive__6t7mZ {
    background-color: #84C631;
}
#Footer_mobileFooterInactive__uziwn {
    background-color: #FFEFEF;
}

.Footer_mobileFooterText__eLsCG {
    position: absolute;
    left: 0px;
    width: 100%;
    text-align: center;
    font-family: "SF Pro", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    top: 13px;
}
#Footer_mobileFooterActiveText__abRZK {
    color: white;
}
#Footer_mobileFooterInactiveText__7Up37 {
    color: #A7A7A7;
}`, "",{"version":3,"sources":["webpack://./src/Footer.module.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,qBAAqB;IACrB,+DAAmE;AACvE;AACA;IACI,qBAAqB;IACrB,+DAAiD;AACrD;AACA,GAAG;;AAEH;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;IACV,YAAY;IACZ,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,SAAS;AACb;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":["/* Font imports */\n@font-face {\n    font-family: \"Outfit\";\n    src: url('./fonts/Outfit-VariableFont_wght.ttf') format('truetype');\n}\n@font-face {\n    font-family: \"SF Pro\";\n    src: url('./fonts/SF-Pro.ttf') format('truetype');\n}\n/**/\n\n.mobileFooter {\n    position: absolute;\n    bottom: 0px;\n    left: 0px;\n    right: 0px;\n    height: 45px;\n    cursor: pointer;\n}\n#mobileFooterActive {\n    background-color: #84C631;\n}\n#mobileFooterInactive {\n    background-color: #FFEFEF;\n}\n\n.mobileFooterText {\n    position: absolute;\n    left: 0px;\n    width: 100%;\n    text-align: center;\n    font-family: \"SF Pro\", sans-serif;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 19px;\n    top: 13px;\n}\n#mobileFooterActiveText {\n    color: white;\n}\n#mobileFooterInactiveText {\n    color: #A7A7A7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileFooter": `Footer_mobileFooter__tJqeI`,
	"mobileFooterActive": `Footer_mobileFooterActive__6t7mZ`,
	"mobileFooterInactive": `Footer_mobileFooterInactive__uziwn`,
	"mobileFooterText": `Footer_mobileFooterText__eLsCG`,
	"mobileFooterActiveText": `Footer_mobileFooterActiveText__abRZK`,
	"mobileFooterInactiveText": `Footer_mobileFooterInactiveText__7Up37`
};
export default ___CSS_LOADER_EXPORT___;
