import React from 'react';
import styles from './Lookup.module.css';
import flightStyles from './Flight.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES } from '../util';
import { usePostHog } from 'posthog-js/react';
import { IndividualFlight } from './Flight';

import search from './images/search.svg';
import arrow from './images/arrow.svg';
import checkmark from './images/checkmark.svg';

function Lookup(props) {
    const posthog = usePostHog();

    const loginClicked = () => {
        posthog.capture("input_confirmation_number", {confirmation: document.getElementById("confirmationNumber").value});
        props.inputConfirmationNumber(document.getElementById("confirmationNumber").value);
    }

    if (window.innerWidth < 1005) {
        if (props.state != DETAILS_STATES.LOOKUP && props.state != DETAILS_STATES.FLIGHT) {
            return (<div />);
        }
        else {
            let allFlightsSelected = true;
            let anyFlightsSelected = false;
            for (let i = 0; i < props.data.flights.length; i++) {
                if (props.data.flights[i] == false) {
                    allFlightsSelected = false;
                }
                else {
                    anyFlightsSelected = true;
                }
            }
            return (
                <div className={globalStyles.mobileStepOuter} style={{height: `${props.state == DETAILS_STATES.FLIGHT ? 376 + 120 * props.data.flights.length : 249}px`, width: `${Math.min(window.innerWidth - 40, 560)}px`, left: `${window.innerWidth < 600 ? 20 : window.innerWidth / 2 - 280}px`}}>
                    <div className={globalStyles.mobileStepTitle}>Look Up Your Trip</div>
                    <div id={styles.mobileTextBox}>
                        <input type="text" id="confirmationNumber" className={globalStyles.inputField} placeholder="e.x ABCGEF" />
                        <div className={globalStyles.inputFieldTitle}>Confirmation Number</div>
                    </div>
                    <div id={styles.mobileSearch} onClick={loginClicked}>
                        <div id={styles.searchText}>Search</div>
                        <img src={search} id={styles.searchImg} />
                    </div>
                    {
                        props.state == DETAILS_STATES.FLIGHT ?
                        <div>
                            <div className={globalStyles.mobileStepTitle} style={{top: "246px"}}>Select Flight</div>
                            <div id={flightStyles.mobileFlightsOuter}>
                                {
                                    props.data.flights.map((_, index) => 
                                        <IndividualFlight 
                                            key={index} 
                                            index={index} 
                                            data={props.data} 
                                            baseData={props.baseData} 
                                            changeFlights={props.changeFlights} />)
                                }
                                <div id={flightStyles.mobileSelectAll} className={allFlightsSelected ? flightStyles.flightSelected : ""} onClick={() => props.changeFlights(props.data.flights.length)}>
                                    {
                                        allFlightsSelected ? 
                                        <div className={flightStyles.selected} style={{top: "16px"}}>
                                            <img src={checkmark} className={flightStyles.selectedImg} />
                                        </div>
                                        :
                                        <div className={flightStyles.selector} style={{top: "16px"}} />
                                    }
                                    <div id={flightStyles.selectAllText}>All Flights In Your Trip</div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                </div>
            );
        }
    }
    else if (props.state == DETAILS_STATES.LOOKUP) {
        return (
            <div className={globalStyles.stepOuter} style={{height: "263px"}}>
                <div className={globalStyles.stepTitle}>Look Up Your Trip</div>
                <div id={styles.textBox}>
                    <input type="text" id="confirmationNumber" className={globalStyles.inputField} placeholder="e.x ABCGEF" />
                    <div className={globalStyles.inputFieldTitle}>Confirmation Number</div>
                </div>
                <div id={styles.search} onClick={loginClicked}>
                    <div id={styles.searchText}>Search</div>
                    <img src={search} id={styles.searchImg} />
                </div>
                <div id={styles.cantFind}><u>Can't Find Your Confirmation Number?</u></div>
            </div>
        )
    }
    return (
        <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
            <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Confirmation Code: {props.confirmation}</div>
            <div className={globalStyles.changeTrip} onClick={props.deletePopup}>
                <div className={globalStyles.changeTripText}>Change Trip</div>
                <img src={arrow} className={globalStyles.changeTripImg} />
            </div>
        </div>
    );
}

export default Lookup;