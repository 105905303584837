import React from 'react';
import { DEMO_SCENARIOS, DEFAULT_SCENARIO, ContainsEligibleFlights, BASE_STATE, CLAIM_STATUS, ISSUE_CATEGORIES, MENU_STATES, classification_prompt, seenFeedbackCategories, DETAILS_STATES, sendHTTPRequest, isValidUUID, getFlightStatus, getAirportName } from './util';

import Anthropic from "@anthropic-ai/sdk";

import Header from './NotForm/Header';
import Title from './NotForm/Title';
import Menu from './NotForm/Menu';
import Form from './Form/Form';
import Footer from './Footer';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = JSON.parse(JSON.stringify(BASE_STATE));

        this.updateStateFromDatabase = this.updateStateFromDatabase.bind(this);
        this.updateMenuState = this.updateMenuState.bind(this);
        this.updateDetailsState = this.updateDetailsState.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.inputConfirmationNumber = this.inputConfirmationNumber.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
        this.changeFlights = this.changeFlights.bind(this);
        this.changeBags = this.changeBags.bind(this);
        this.changePassengers = this.changePassengers.bind(this);
        this.addClaim = this.addClaim.bind(this);
        this.addBagClaim = this.addBagClaim.bind(this);
        this.removeClaim = this.removeClaim.bind(this);
        this.removeBagClaim = this.removeBagClaim.bind(this);
        this.updatePaymentForm = this.updatePaymentForm.bind(this);
        this.updatePaymentFormSelected = this.updatePaymentFormSelected.bind(this);
        this.submitClaim = this.submitClaim.bind(this);
        this.removeDisputed = this.removeDisputed.bind(this);
        this.tellUsAboutYourBag = this.tellUsAboutYourBag.bind(this);
        this.selectBagMatch = this.selectBagMatch.bind(this);
        this.resetConfirmationCode = this.resetConfirmationCode.bind(this);
        this.restartWorkflow = this.restartWorkflow.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.updatePaymentDetails = this.updatePaymentDetails.bind(this);
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidMount() {
        if (this.state.menuState == MENU_STATES.LOADING) {
            let urlString = window.location.href.split("?");
            if (urlString.length > 1 && urlString[1].includes("=") && isValidUUID(urlString[1].split("=")[1])) {
                this.updateStateFromDatabase(urlString[1].split("=")[1]);
            }
            else {
                this.setState({menuState: MENU_STATES.DETAILS});
            }
        }
    }

    handleWindowResize(event) {
        event.preventDefault();
        this.setState({windowWidth: window.innerWidth});
    }

    updateStateFromDatabase(id) {
        sendHTTPRequest({
            link: process.env.REACT_APP_PUBLIC_GANDER_API_URL + "/getFlightInfo/" + id,
            request: {
                method: "GET"
            },
            decodeCallback: (response) => {
                return response.json();
            },
            responseCallback: (response) => {
                response.loyalty = true;
                for (let i = 0; i < response.flights.length; i++) {
                    response.flights[i].routeStart = getAirportName(response.flights[i].routeStarAbr);
                    response.flights[i].routeEnd = getAirportName(response.flights[i].routeEndAbr);
                    response.flights[i].status = getFlightStatus(response.flights[i].delay);
                    let startTime = new Date(response.flights[i].departureTime * 1000);
                    let endTime = new Date(response.flights[i].arrivalTime * 1000);
                    response.flights[i].date = startTime.toLocaleDateString();
                    response.flights[i].time = startTime.toLocaleTimeString() + " - " + endTime.toLocaleTimeString();
                }
                let newFlights = [];
                for (let i = 0; i < response.flights.length; i++) {
                    newFlights.push(true);
                }
                let newPassengers = [];
                for (let i = 0; i < response.passengers.length; i++) {
                    newPassengers.push(true);
                }
                let copyData = {...this.state.data, category: ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT, confirmationCode: response.confirmationNumber, flights: newFlights, passengers: newPassengers, containsEligibleFlights: true};
                this.setState({baseData: response, data: copyData, menuState: MENU_STATES.DETAILS, detailsState: DETAILS_STATES.ELIGIBILITY});
            }
        })
    }

    updateMenuState(state) {
        this.setState({menuState: state});
    }

    updateDetailsState(state) {
        let detailsStatesVisited = this.state.detailsStatesVisited;
        detailsStatesVisited[state] = true;
        this.setState({detailsState: state, menuState: state == DETAILS_STATES.CONFIRM ? MENU_STATES.CONFIRM : MENU_STATES.DETAILS, detailsStatesVisited});
    }

    selectCategory(category) {
        if (category == ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT || 
            category == ISSUE_CATEGORIES.PASSENGER_RIGHTS ||
            category == ISSUE_CATEGORIES.FEEDBACK ||
            category == ISSUE_CATEGORIES.BAG_DAMAGE ||
            category == ISSUE_CATEGORIES.LOST_BAG)
        {
            let copyState = JSON.parse(JSON.stringify(BASE_STATE));
            copyState.data.category = category;
            copyState.menuState = MENU_STATES.DETAILS;
            this.setState(copyState);
        }
    }

    inputConfirmationNumber(confNumber) {
        let newBaseData;
        if (confNumber in DEMO_SCENARIOS) {
            console.log("Using demo scenario");
            newBaseData = JSON.parse(JSON.stringify(DEMO_SCENARIOS[confNumber]));
            let newFlights = [];
            for (let i = 0; i < newBaseData.flights.length; i++) {
                newFlights.push(false);
            }
            let newBags = [];
            for (let i = 0; i < newBaseData.bags.length; i++) {
                newBags.push(false);
            }
            let newPassengers = [];
            for (let i = 0; i < newBaseData.passengers.length; i++) {
                newPassengers.push(false);
            }
            let copyData = {...this.state.data, confirmationCode: confNumber, flights: newFlights, bags: newBags, passengers: newPassengers};
            let detailsStatesVisited = this.state.detailsStatesVisited;
            detailsStatesVisited[DETAILS_STATES.LOOKUP] = true;
            this.setState({detailsState: DETAILS_STATES.FLIGHT, data: copyData, baseData: newBaseData});
        }
        else {
            this.updateStateFromDatabase(confNumber);
        }
    }

    changeSelection(index, array) {
        if (index == array.length) {
            let foundFalse = false;
            for (let i = 0; i < array.length; i++) {
                if (array[i] == false) {
                    foundFalse = true;
                    break;
                }
            }
            // if all true, turn all to false
            // else, turn all to true
            for (let i = 0; i < array.length; i++) {
                array[i] = foundFalse;
            }
        }
        else {
            array[index] = !array[index];
        }
    }

    changeFlights(flightNo) {
        let newFlights = this.state.data.flights;
        this.changeSelection(flightNo, newFlights);
        let copyData = {...this.state.data, flights: newFlights, containsEligibleFlights: ContainsEligibleFlights(this.state.baseData.flights, this.state.data.flights)};
        this.setState({data: copyData});
    }

    changeBags(bagNo) {
        let newBags = this.state.data.bags;
        if (this.state.data.category == ISSUE_CATEGORIES.LOST_BAG) {
            newBags = [bagNo == 0, bagNo == 1];
        }
        else {
            this.changeSelection(bagNo, newBags);
        }
        let copyData = {...this.state.data, bags: newBags};
        this.setState({data: copyData});
    }

    changePassengers(passNo) {
        let newPassengers = this.state.data.passengers;
        this.changeSelection(passNo, newPassengers);
        let copyData = {...this.state.data, passengers: newPassengers};
        this.setState({data: copyData});
    }

    addClaim(claim) {
        let claims = this.state.data.claims;
        if (this.state.data.category != ISSUE_CATEGORIES.PASSENGER_RIGHTS || this.state.data.claims.length == 0) {
            claims.push(claim);
        }
        let copyData = {...this.state.data, claims: claims};
        this.setState({data: copyData});
    }

    addBagClaim(claim) {
        let bagClaims = this.state.data.bagClaims;
        bagClaims.push(claim);
        let copyData = {...this.state.data, bagClaims: bagClaims};
        this.setState({data: copyData});
    }

    removeClaim(index) {
        let claims = this.state.data.claims;
        claims.splice(index, 1);
        let copyData = {...this.state.data, claims: claims};
        this.setState({data: copyData});
    }

    removeBagClaim(index) {
        let bagClaims = this.state.data.bagClaims;
        bagClaims.splice(index, 1);
        let copyData = {...this.state.data, bagClaims: bagClaims};
        this.setState({data: copyData});
    }

    updatePaymentForm(index) {
        let copyData = {...this.state.data, paymentForm: index};
        this.setState({data: copyData});
    }
    updatePaymentFormSelected(index) {
        let copyData = {...this.state.data, paymentFormSelected: index};
        this.setState({data: copyData});
    }

    async submitClaim(amount, details) {
        if (this.state.data.category == ISSUE_CATEGORIES.FEEDBACK) {
            sendHTTPRequest({
                link: process.env.REACT_APP_PUBLIC_GANDER_API_URL + "/getFeedbackClassification",
                request: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ details })
                },
                decodeCallback: (response) => {
                    return response.json();
                },
                responseCallback: (data) => {
                    let copyData = {...this.state.data, paymentAmount: amount, paymentDetails: data.feedbackCategory};
                    this.setState({data: copyData, menuState: MENU_STATES.RESOLUTION});
                },
                errorCallback: (error) => {
                    console.log(error);
                }
            });
        }
        else {
            let copyData = {...this.state.data, paymentAmount: amount, paymentDetails: details};
            this.setState({data: copyData, menuState: MENU_STATES.RESOLUTION});
        }
    }

    removeDisputed() {
        let claims = [];
        for (let i = 0; i < this.state.data.claims.length; i++) {
            if (this.state.data.claims[i].status != CLAIM_STATUS.DISPUTED) {
                claims.push(this.state.data.claims[i]);
            }
        }
        let copyData = {...this.state.data, claims: claims};
        this.setState({data: copyData});
    }

    tellUsAboutYourBag(bag) {
        sendHTTPRequest({
            link: process.env.REACT_APP_PUBLIC_GANDER_API_URL + "/mostSimilarBag",
            request: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bag)
            },
            decodeCallback: (response) => {
                return response.json();
            },
            responseCallback: (data) => {
                let copyData = {...this.state.data, bagMatches: data.bags};
                this.setState({data: copyData, detailsState: DETAILS_STATES.BAG_IDENTIFICATION});
            },
            errorCallback: (error) => {
                console.log(error);
            }
        });
    }

    selectBagMatch(index) {
        let copyData = {...this.state.data, bagMatch: index};
        this.setState({data: copyData, detailsState: DETAILS_STATES.BAG_CONFIRMATION});
    }

    resetConfirmationCode() {
        let newState = JSON.parse(JSON.stringify(BASE_STATE));
        newState.menuState = MENU_STATES.DETAILS;
        newState.data.category = this.state.data.category;
        this.setState(newState);
    }

    restartWorkflow() {
        if (this.state.data.category == ISSUE_CATEGORIES.FEEDBACK) {
            seenFeedbackCategories.push(`${this.state.data.paymentDetails}`);
        }
        this.setState(JSON.parse(JSON.stringify(BASE_STATE)));
    }

    updatePaymentDetails(details) {
        let copyData = {...this.state.data, paymentDetails: details};
        this.setState({data: copyData});
    }

    render() {
        return (
            <div>
                <Header 
                    confirmation={this.state.data.confirmationCode} />
                <Title 
                    state={this.state.menuState} 
                    detailsState={this.state.detailsState} 
                    updateState={this.updateMenuState} 
                    issueCategory={this.state.data.category} 
                    updateSearchTerm={(term) => this.setState({searchTerm: term})}
                    searchTerm={this.state.searchTerm} />
                <Form
                    menuState={this.state.menuState} 
                    detailsState={this.state.detailsState}
                    detailsStatesVisited={this.state.detailsStatesVisited}
                    baseData={this.state.baseData}
                    data={this.state.data}
                    updateDetailsState={this.updateDetailsState}
                    selectCategory={this.selectCategory}
                    inputConfirmationNumber={this.inputConfirmationNumber}
                    changeFlights={this.changeFlights}
                    changePassengers={this.changePassengers}
                    addClaim={this.addClaim}
                    addBagClaim={this.addBagClaim}
                    removeClaim={this.removeClaim}
                    removeBagClaim={this.removeBagClaim}
                    updatePaymentForm={this.updatePaymentForm}
                    updatePaymentFormSelected={this.updatePaymentFormSelected}
                    submitClaim={this.submitClaim} 
                    updatePaymentDetails={this.updatePaymentDetails}
                    restartWorkflow={this.restartWorkflow} 
                    removeDisputed={this.removeDisputed} 
                    tellUsAboutYourBag={this.tellUsAboutYourBag}
                    selectBagMatch={this.selectBagMatch}
                    searchTerm={this.state.searchTerm} 
                    clearSearchTerm={() => this.setState({searchTerm: ""})}
                    resetConfirmationCode={this.resetConfirmationCode} 
                    changeBags={this.changeBags} />
                <Menu 
                    state={this.state.menuState} 
                    updateState={this.updateMenuState} 
                    detailsState={this.state.detailsState} 
                    updateDetailsState={this.updateDetailsState} />
                <Footer
                    detailsState={this.state.detailsState}
                    menuState={this.state.menuState}
                    updateState={this.updateDetailsState}
                    updatePaymentForm={this.updatePaymentForm}
                    submitClaim={this.submitClaim}
                    data={this.state.data} />
             </div>
        );
    }
}

export default App;